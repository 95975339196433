import {Component, OnInit} from '@angular/core'
import {DataService} from '../../services/data.service'

interface Translations {
  [key: string]: string
}

@Component({
  selector: 'spb-id-list-dialog',
  templateUrl: './id-list-dialog.component.html',
  styleUrls: ['./id-list-dialog.component.scss']
})
export class IdListDialogComponent implements OnInit {
  public data: any

  constructor(
    private dataService: DataService
  ) {
  }

  public ngOnInit() {
    this.data = this.dataService.userData()
    this.translate()
  }

  private translate() {
    const translations: Translations = {
      'Invalid QR code': 'Felaktig QR kod',
      'QR code missing or empty': 'QR kod saknas eller är tom',
      'invalidParameters': 'Felaktiga parametrar',
      'verificationFailed': 'Misslyckad',
      'ID card verification not passed': 'Verifiering av ID-kort misslyckades',
    }

    if (translations.hasOwnProperty(this.data.details)) {
      this.data.details = translations[this.data.details]
    }

    if (translations.hasOwnProperty(this.data.errorCode)) {
      this.data.errorCode = translations[this.data.errorCode]
    }
  }
}
