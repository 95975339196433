{
  "name": "id-app",
  "version": "0.1.6-69-update-a17.0",
  "scripts": {
    "watch": "ng test",
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "preinstall": "./scripts/configure.sh",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.2",
    "@angular/common": "^17.0.2",
    "@angular/compiler": "^17.0.2",
    "@angular/core": "^17.0.2",
    "@angular/forms": "^17.0.2",
    "@angular/platform-browser": "^17.0.2",
    "@angular/platform-browser-dynamic": "^17.0.2",
    "@angular/router": "^17.0.2",
    "@angular/service-worker": "^17.0.2",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular-eslint/builder": "17.0.1",
    "@angular-eslint/eslint-plugin": "17.0.1",
    "@angular-eslint/eslint-plugin-template": "17.0.1",
    "@angular-eslint/schematics": "17.0.1",
    "@angular-eslint/template-parser": "17.0.1",
    "@angular/cdk": "^17.0.0",
    "@angular/cli": "~17.0.0",
    "@angular/compiler-cli": "^17.0.2",
    "@angular/material": "^17.0.0",
    "@angular/pwa": "^17.0.0",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.2",
    "@types/hammerjs": "^2.0.43",
    "@types/jasmine": "~5.1.1",
    "@types/sparbanken-syd-bil": "^0.0.6",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "hammerjs": "^2.0.8",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.31",
    "qr-scanner": "^1.4.2",
    "stylelint": "^15.11.0",
    "stylelint-config-sass-guidelines": "^10.0.0",
    "typescript": "~5.2.2"
  }
}
