import { Component } from '@angular/core'
import version from '../assets/package.json'
import {Router} from '@angular/router'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'
import {ConfigService} from './services/config.service'
import {environment} from '../environments/environment'
import {switchMap} from 'rxjs'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public version = version.version

  constructor(
    private router: Router,
    private singleSignOnService: SingleSignOnService,
    public configService: ConfigService) {
  }

  public logout(logout: boolean): void {
    if (logout) {
      this.singleSignOnService.deleteToken(environment.authServiceUrl).pipe(
        switchMap(() => this.configService.resetToken()))
        .subscribe({
          next: () => this.router.navigate(['login'])
        })
    }
  }
}
