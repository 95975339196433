import {APP_INITIALIZER, isDevMode, NgModule} from '@angular/core'
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {ScannerComponent} from './3-scanner/scanner.component'
import {QrFactory} from './application/qr-factory.class'
import {MatButtonModule} from '@angular/material/button'
import {QR_SCANNER_FACTORY} from './application/qr-scanner.provider'
import {HeaderComponent} from './common/header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {MatIconModule} from '@angular/material/icon'
import {LoginComponent} from './login/login.component'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {AuthInterceptor} from './application/auth-interceptor'
import {HTTP_INTERCEPTORS} from '@angular/common/http'
import {ConfigService} from './services/config.service'
import {ServiceWorkerModule} from '@angular/service-worker'
import {IdListComponent} from './4-id-list/id-list.component'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'
import {MatInputModule} from '@angular/material/input'
import {ReactiveFormsModule} from '@angular/forms'
import {MatDialogModule} from '@angular/material/dialog'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {IdListDialogComponent} from './common/id-list-dialog/id-list-dialog.component'
import {MatTabsModule} from '@angular/material/tabs'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {HammerConfig, StartComponent} from './1-start/start.component'
import {WelcomeComponent} from './2-welcome/welcome.component'
import {HelpComponent} from './5-help/help.component'
import {NgOptimizedImage} from '@angular/common'
import {
  InstallInstructionsDialogComponent
} from './common/install-instructions-dialog/install-instructions-dialog.component'
import {MatSelectModule} from '@angular/material/select'
import {WaitDialogComponent} from './common/wait-dialog/wait-dialog.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ScannerComponent,
    HeaderComponent,
    IdListComponent,
    IdListDialogComponent,
    StartComponent,
    WelcomeComponent,
    HelpComponent,
    InstallInstructionsDialogComponent,
    WaitDialogComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ThemeModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        ReactiveFormsModule,
        BankIdModule,
        MatDialogModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MatTabsModule,
        NgOptimizedImage,
        HammerModule,
        MatSelectModule,
    ],
  providers: [
    {provide: QR_SCANNER_FACTORY, useClass: QrFactory},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    ConfigService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => configService.checkStatus,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
