<div class="holder">
  <div class="button-holder">
    <div>Klicka på iconen <span><img src="assets/ios-share.svg" alt="share" height="20px"></span> i adressfältet och
      välj ”Lägg till på hemskärmen” för att installera appen.
    </div>
    <img class="arrow" src="assets/arrow.png" alt="arrow">
  </div>
  <div>
    <button color="primary" mat-dialog-close mat-raised-button>Stäng</button>
  </div>
</div>
