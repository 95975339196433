<div>
  <div class="holder">
    <div class="middle">
      <div class="logo">
        Digitalt ID-kort
      </div>
      @if (ready) {
        <div class="middle">
          <div class="spb-filler"></div>
          @if (configService.configState$ | async; as config) {
            <div class="middle-menu">
              @if (config.ready) {
                <div>
                  <button [matMenuTriggerFor]="menu" mat-button>
                    <mat-icon>menu</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="goToAdmin()">Admin</button>
                    <button (click)="logout()" mat-menu-item>Logga ut</button>
                    <button (click)="install()" mat-menu-item>Installera</button>
                  </mat-menu>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
