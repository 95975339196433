<div
  (swipeleft)="handleSwipeLeft.emit()"
  (swiperight)="handleSwipeRight.emit()">
  <div
    class="video-holder">
    <video #video class="video-container"></video>
  </div>
  <div class="selector-holder">
    <p>Har du problem att skanna QR-koden kan du prova att byta kamera här:</p>
    <mat-form-field>
      <mat-label>Byt kamera</mat-label>
      <mat-select [formControl]="selectedCamera">
        @for (camera of cameraList; track camera.id) {
        <mat-option [value]="camera.id">{{camera.label}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>

