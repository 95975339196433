<div class="holder">
  <div class="text-container  mat-elevation-z8 ">
    <p>Välkommen till tjänsten för verifiering av digitalt ID-kort. För att kunna använda tjänsten behöver du först
      identifiera dig med BankID.</p>
    <spb-bankid [bankIdUrl]="bankIdUrl"
                (accessToken)="configService.setToken($event)"
                [domain]="domain"
    ></spb-bankid>
  </div>
</div>
<div class="spb-filler"></div>
