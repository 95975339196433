import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/local-storage.provider'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {ACCESS_TOKEN_NAME, USER_ROLE} from '../application/data-types'
import {HelperService, SparbankenUser, TokenPayload} from '@sparbanken-syd/sparbanken-syd-bankid'

/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface SpbConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null

  /**
   * idUser - Can use the tool
   */
  idUser: boolean

  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public configState$: Observable<SpbConfiguration>

  /**
   * Publish and subscribe to the currently logged-in user here...
   */
  public currentUser$: Observable<SparbankenUser>

  private pCurrentUser$: ReplaySubject<SparbankenUser> = new ReplaySubject<SparbankenUser>(1)

  /**
   * Publish and subscribe to the current SpbConfiguration here...
   */
  private currentConfig: SpbConfiguration = {ready: false} as any
  private pConfigState$: BehaviorSubject<SpbConfiguration> =
    new BehaviorSubject<SpbConfiguration>({ready: false} as any)


  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.configState$ = this.pConfigState$.asObservable()
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * Sets the access token and returns an observable, used primarily
   * by the Bootstrap process
   */
  public checkStatus = (): Observable<any> => {
    this.isLoggedIn()
    return of({})
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): void {
    const token = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME)
    // The set token will throw if not a valid string
    try {
      this.setToken(token as string)
    } catch (e) {
      // Can happen and is quite common.
    }
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    this.currentConfig = {
      token: HelperService.ValidateToken(token),
      idUser: false,
      ready: false
    }
    if (this.currentConfig.token === null) {
      this.resetToken().subscribe()
    }
    const payload = HelperService.GetTokenPayload(token) as TokenPayload
    if (this.currentConfig.token) {
      this.currentConfig.idUser = payload.roles.indexOf(USER_ROLE) !== -1
      this.currentConfig.ready = this.currentConfig.idUser
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
      this.helperService.getCurrentUser(environment.authServiceUrl).subscribe({
        next: (user: SparbankenUser) => this.pCurrentUser$.next(user)
      })

    }
    this.pConfigState$.next(this.currentConfig)
  }

  /**
   * Reset what ever access token we might have had, used when logging out
   */
  public resetToken(): Observable<void> {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false} as any
    this.pCurrentUser$.next({name: '', roles: []} as any) // Lame reset
    this.pConfigState$.next(this.currentConfig)
    return of(undefined)
  }
}
